import { useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";

import Loader from "../../../../../molecules/loader/Loader";
import PollingLoader from "../../../../../organisms/polling-loader/PollingLoader";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { getCurrentURLSearchParams } from "../../../../../utils/browser-features";

const Quoting = (): JSX.Element => {
  const currentURLSearchParams = getCurrentURLSearchParams();
  const noEditable = currentURLSearchParams?.get("no_editable");
  const { t } = useTranslation();
  const result = useResult();
  const [nextUrl, setNextUrl] = useState<null | string>(null);

  useEffect(() => {
    if (!result) {
      return;
    }

    if (result.hasOnlyGlQuote) {
      setNextUrl(`/results/${result?.uuid}/details/?gl_only=1`);

      return;
    }

    setNextUrl(`/results/${result?.uuid}/${noEditable ? `?no_editable=1` : ""}`);
  }, [result, noEditable]);

  return nextUrl ? (
    <PollingLoader
      nextUrl={nextUrl}
      statusesCallbacks={{
        created: ({ waitAndRetry }) => waitAndRetry(),
        in_progress: ({ waitAndRetry }) => waitAndRetry(),
      }}
      timeoutToNavigate={3500}
    >
      <Loader title={t("pg_quoting.loader.title", { petName: result?.pet_name })} />
    </PollingLoader>
  ) : (
    <></>
  );
};

export default Quoting;
